<template>
  <div>
    <div style="float:left">
      <el-checkbox-group :size="size" v-model="tValue" :loading="loading" @change="handleChange" v-if="isButton">
        <el-checkbox-button v-for="item in optionsData" :label="item[valueKey]" :key="item[valueKey]"
          :disabled="item.disabled">{{ item[labelKey] }}</el-checkbox-button>
      </el-checkbox-group>

      <el-checkbox-group :size="size" v-model="tValue" :loading="loading" @change="handleChange" v-if="!isButton">
        <el-checkbox v-for="item in optionsData" :label="item[valueKey]" :key="item[valueKey]"
          :disabled="item.disabled">{{ item[labelKey]}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-if="isTips">
      <p style="float:left;padding-left:20px;color: red;">提示：{{ valTips }}</p>
    </div>
  </div>
</template>
<script>
import { Empty } from 'element-ui'
export default {
  name: 'TryCheckBox',
  props: {
    url: { // 接口地址
      type: String,
      default: undefined
    },
    valueKey: { // 列表中选项的值对应的key
      type: String,
      default: 'value'
    },
    labelKey: { // 列表中选项的标签对应的key
      type: String,
      default: 'key'
    },
    dictName: { // 字典值
      type: String,
      default: ''
    },
    isEdit: { // 是否编辑模式
      type: Boolean,
      default: false
    },
    isButton: { // 是否启用按钮模式
      type: Boolean,
      default: false
    },
    // 绑定的值
    value: {
      type: [String, Number, Array],
      default: undefined
    },

    size: { // medium/small/mini
      type: String,
      default: 'medium'
    },

    options: {//数据源
      type: [Array, Object],
      default: undefined
    },
    require: {//是否验证必填
      type: Boolean,
      default: false
    },
    valTips: {//验证提示
      type: String,
      default: '必选项！'
    },
    parameData:{//Url带入参数
      type: [String, Number, Array],
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      isTips: false,//是否显示提示
      tValue: this.value,
      optionsData: this.options,
    }
  },
  watch: {
    value(n, o) { // 监听父组件值变动，子组件也要变动
      this.tValue = n;
      if (this.require) {
        if ((n == undefined || n.length == 0)) {
          this.isTips = true;
        } else {
          this.isTips = false;
        }
      }
    },
    options(n, o) {
      this.optionsData = n;
    }
  },
  methods: {
    // 子组件值变化要通过父组件
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>
