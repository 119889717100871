<template>
    <div>

        <Editor :id="tinymceId" :init="init" :disabled="disabled" v-model="myValue"></Editor>
        <div id="wdpst"></div>
    </div>
</template>

<script>
    //引入tinymce-vue
    import Editor from '@tinymce/tinymce-vue'
    import {API_ROOT} from "@config";

    export default {
        name: "TryEditor",
        components: {
            Editor
        },
        props: {
            //编号
            id: {
                type: String
            },
            //内容
            value: {
                type: String,
                default: ''
            },
            //是否禁用
            disabled: {
                type: Boolean,
                default: false
            },
            // 是否开启全功能
            allFunctions: {
                type: Boolean,
                default: () => {
                    return true;
                },
            },
            // 高度 默认300
            height: {
                type: Number,
                default: () => {
                    return 600;
                },
            },

            // 宽度 默认900
            width: {
                type: Number,
                default: () => {
                    return 900;
                },
            },

            keep_styles: true
        },
        mounted: function () {
        },
        data() {
            let that = this;
            return {
                tinymceId: this.id || 'vue-tinymce' + Date.parse(new Date()),
                myValue: this.value,
                init: {
                    selector: this.tinymceId,
                    //汉化,路径是自定义的，一般放在public或static里面，汉化文件要自己去下载
                    language: 'zh_CN',
                    //皮肤
                    skin: 'oxide',
                    convert_urls: false,
                    automatic_uploads: true,
                    images_upload_url: API_ROOT + "/protal/tool/upLoadimage?accesstoken=" + sessionStorage.getItem("token"),
                    //隐藏技术支持
                    branding: false,
                    //隐藏底栏的元素路径
                    elementpath: false,
                    //关闭菜单
                    menubar: true,// 菜单栏
                    //隐藏状态栏
                    statusbar: true,
                    //高度
                    height: this.height,
                    // //公式插件
                    external_plugins: {},

                    buttonbar: 'layoutButton',
                    //插件
                    plugins: this.allFunctions
                        ? "link image media table lists fullscreen quickbars wordcount paste emoticons code autolink autosave imagetools paste preview code bdmap"
                        : "link image media table lists quickbars wordcount paste emoticons code autolink autosave imagetools paste preview code bdmap",
                    //工具栏
                    toolbar1: this.allFunctions
                        ? "code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | fullscreen   indent2em lineheight formatpainter axupimgs | styleselect  fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media charmap  | wordpaster pptimport pdfimport netpaster  bdmap emoticons hr pagebreak insertdatetime print preview layoutButton"
                        : "undo redo| fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | image media  | wordcount code | wordpaster pptimport pdfimport netpaster  code bdmap layoutButton",

                    toolbar_drawer: "sliding",
                    quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor | wordpaster pptimport pdfimport netpaster",
                    fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
                    quickbars_insert_toolbar: "image quicktable",
                    style_formats: [
                        //新增 首行缩进与行高 配置
                        {
                            title: '首行缩进',
                            block: 'p',
                            styles: {
                                'text-indent': '2em'
                            }
                        },


                        {
                            title: '段落间距',
                            items: [{
                                title: '1',
                                styles: {
                                    'padding-bottom': '1em'
                                },
                                block: 'p',
                            },
                                {
                                    title: '1.5',
                                    styles: {
                                        'padding-bottom': '1.5em'
                                    },
                                    block: 'p',
                                },
                                {
                                    title: '2',
                                    styles: {
                                        'padding-bottom': '2em'
                                    },
                                    block: 'p',
                                },
                                {
                                    title: '2.5',
                                    styles: {
                                        'padding-bottom': '2.5em'
                                    },
                                    block: 'p',
                                },
                                {
                                    title: '3',
                                    styles: {
                                        'padding-bottom': '3em'
                                    },
                                    block: 'p',
                                }
                            ]
                        },

                        //默认的配置
                        {
                            title: 'Headings',
                            items: [
                                {
                                    title: 'Heading 1',
                                    format: 'h1'
                                },
                                {
                                    title: 'Heading 2',
                                    format: 'h2'
                                },
                                {
                                    title: 'Heading 3',
                                    format: 'h3'
                                },
                                {
                                    title: 'Heading 4',
                                    format: 'h4'
                                },
                                {
                                    title: 'Heading 5',
                                    format: 'h5'
                                },
                                {
                                    title: 'Heading 6',
                                    format: 'h6'
                                }
                            ]
                        },
                        {
                            title: 'Inline',
                            items: [
                                {
                                    title: 'Bold',
                                    icon: 'bold',
                                    format: 'bold'
                                },
                                {
                                    title: 'Italic',
                                    icon: 'italic',
                                    format: 'italic'
                                },
                                {
                                    title: 'Underline',
                                    icon: 'underline',
                                    format: 'underline'
                                },
                                {
                                    title: 'Strikethrough',
                                    icon: 'strikethrough',
                                    format: 'strikethrough'
                                },
                                {
                                    title: 'Superscript',
                                    icon: 'superscript',
                                    format: 'superscript'
                                },
                                {
                                    title: 'Subscript',
                                    icon: 'subscript',
                                    format: 'subscript'
                                },
                                {
                                    title: 'Code',
                                    icon: 'code',
                                    format: 'code'
                                }
                            ]
                        },
                        {
                            title: 'Blocks',
                            items: [
                                {
                                    title: 'Paragraph',
                                    format: 'p'
                                },
                                {
                                    title: 'Blockquote',
                                    format: 'blockquote'
                                },
                                {
                                    title: 'Div',
                                    format: 'div'
                                },
                                {
                                    title: 'Pre',
                                    format: 'pre'
                                }
                            ]
                        },
                        {
                            title: 'Alignment',
                            items: [
                                {
                                    title: 'Left',
                                    icon: 'alignleft',
                                    format: 'alignleft'
                                },
                                {
                                    title: 'Center',
                                    icon: 'aligncenter',
                                    format: 'aligncenter'
                                },
                                {
                                    title: 'Right',
                                    icon: 'alignright',
                                    format: 'alignright'
                                },
                                {
                                    title: 'Justify',
                                    icon: 'alignjustify',
                                    format: 'alignjustify'
                                }
                            ]
                        }
                    ],


                    //粘贴data格式的图像
                    paste_data_images: false,
                    width: this.width,
                    images_dataimg_filter: function (img) {
                        return img.hasAttribute('internal-blob');
                    },
                    images_upload_handler: (blobInfo, success, failure, progress) => {

                        // 方法1：用base64的图片形式上传图片
                        // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
                        // success(img)

                        // 方法2：上传
                        const xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open("POST", "/api/tool/uploadimg");
                        xhr.setRequestHeader('accesstoken', sessionStorage.getItem("token"));
                        xhr.upload.onprogress = function (e) {
                            progress((e.loaded / e.total) * 100);
                        };
                        // 成功结果
                        xhr.onload = function () {
                            if (xhr.status != 200) {
                                failure("HTTP Error: " + xhr.status, {remove: true});
                                return;
                            }
                            const json = JSON.parse(xhr.responseText);
                            if (json.status != 200) {
                                failure("图像上传失败:" + json.msg);
                                return;
                            }
                            success(json.data.address);
                        };
                        // 失败结果
                        xhr.onerror = function () {
                            failure("图像上传失败:" + xhr.status);
                        };
                        // 请求数据
                        const formData = new FormData();
                        formData.append("file", blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    },
                    file_picker_callback: (callback, value, meta) => {

                        let _this = this;
                        //文件分类
                        var filetype =
                            ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
                        //后端接收上传文件的地址
                        var upurl = API_ROOT + "/protal/tool/uploadFile";
                        //为不同插件指定文件类型及后端地址
                        switch (meta.filetype) {
                            case "image":
                                filetype = ".jpg, .jpeg, .png, .gif";
                                upurl = API_ROOT + "/protal/tool/upLoadimage";
                                break;
                            case "media":
                                filetype = ".mp3, .mp4";
                                upurl = API_ROOT + "/protal/tool/uploadFile";
                                break;
                            case "file":
                            default:
                        }
                        //模拟出一个input用于添加本地文件
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", filetype);
                        input.click();
                        input.onchange = function () {


                            var file = this.files[0];
                            var xhr, formData;
                            xhr = new XMLHttpRequest();

                            xhr.withCredentials = false;
                            xhr.open("POST", upurl);
                            xhr.setRequestHeader('accesstoken', sessionStorage.getItem("token"));
                            xhr.onload = function () {
                                if (xhr.status != 200) {
                                    alert("文件上传失败：" + xhr.status)

                                    return;
                                }
                                const json = JSON.parse(xhr.responseText);
                                if (json.status != 200) {
                                    alert("文件上传失败：" + json.msg)
                                    return;
                                }
                                //请求成功
                                if (json.status == 200) {
                                    // 组装扔回去
                                    callback(json.data.address, {title: file.name});
                                }
                            };
                            formData = new FormData();
                            formData.append("file", file, file.name);
                            xhr.send(formData);
                        };
                    },
                    setup: function (edt) {

                        edt.ui.registry.addButton('layoutButton', {
                            text: '一键排版',
                            onAction: function (_) {
                                var txt = edt.getContent();

                                //清除所有html空格
                                txt = txt.replace(/&nbsp;/ig, "");

                                //清除所有空行（摘录网上，实测可用）
                                var arrPattern = [
                                    '<p(>|\\s+[^>]*>)(&nbsp|&nbsp;|\\s|　|<br\\s*(\/)?>)*<\/p(>|\\s+[^>]*>)',
                                    '(<br\\s*(\/)?>((\\s|&nbsp;|&nbsp|　)*)){2,}',
                                    '(<p(>|\\s+[^>]*>))((&nbsp|&nbsp;|\\s)*<br\\s*(\/)?>)*((.|\n|\r)*?<\/p(>|\\s+[^>]*>))'
                                ];
                                var arrReplace = ['', '<br />$3', '$1$6'];
                                for (var i = 0; i < arrPattern.length; i++) {
                                    var arrRegExp = new RegExp(arrPattern[i], 'img');
                                    txt = txt.replace(arrRegExp, arrReplace[i]);
                                }

                                //清除所有非空格的空白字符，空格字符去除可能导致元素属性出错
                                txt = txt.replace(/[\f\n\r\t\v]/ig, "");

                                //清除所有span
                                txt = txt.replace(/<(\/span|span).*?>/ig, "");

                                //清除超链接，将网址分离在后
                                txt = txt.replace(/<a.*?href\s*=\s*[\"|\'](.*?)[\"|\'].*?>(.*?)<\/a>/ig, "$2[网址：$1]");
                                //实际运行中发现有些错误的a链接可能根本没有href，所以需要再次清理
                                txt = txt.replace(/<(\/a|a).*?>/ig, "");

                                //清除所有class，为防止有些class不加引号，因此强制规定只清除元素内的class，这种写法最笨和直观，聪明的看下一个
                                txt = txt.replace(/class\s*?=\s*?([‘"])[\s\S]*?\1/ig, '');
                                //清除所有style属性
                                //直接量语法：
                                txt = txt.replace(/style\s*?=\s*?([\'\"])[\s\S]*?\1/ig, "");
                                //RegExp对象语法，可用于自定义变量，比如出了style以外的class，lang等等
                                var v = "style";
                                txt = txt.replace(new RegExp(v + "\\s*?=\\s*?([\'\"])[\\s\\S]*?\\1", "ig"), "");

                                // //清除所有元素属性，超链接可能被清除
                                // txt = txt.replace(/<([a-zA-Z1-6]+)(\s*[^>]*)?>/ig, "<$1>");

                                //如果保留img,p,table标签，则为：
                                txt = txt.replace(/<(?!table|tr|td|\/table|\/tr|\/td|img|p|\/p).*?>/g, "");
                                //给每段落加上样式
                                txt = txt.replace(/<p.*?>/ig, "<p style=\"text-indent:2em;\">");
                                //给图片加上居中样式
                                txt = txt.replace(/<img/ig, "<img style=\"margin: 0px auto; display: block;\" ");
                                //给表格加上居中样式
                                txt = txt.replace(/<table/ig, "<table style=\"border-collapse: collapse; margin-left: auto; margin-right: auto;\"   border=\"1\" ");

                                edt.setContent(txt);
                            }
                        });
                    }
                }
            }
        },
        methods: {},

        watch: {
            //监听内容变化
            value(newValue) {
                this.myValue = newValue
            },
            myValue(newValue) {
                this.$emit('input', newValue)
            }
        }
    }
</script>
<style>
    .tox-notifications-container {
        display: none;
    }

    .tox-tinymce-aux {
        z-index: 5000 !important;
    }
</style>

