import Layout from "@views/layout";

const userinfoRouter = {
  path: "/userinfo",
  name: "USERINFO",
  hidden:true,
  component: Layout,
  redirect: { name: 'USERINFO_LIST' },
  meta: {
    title: "个人中心",
    icon: "icon-xiezilou",
  },
  children: [
    {
      path: "list",
      name: "USERINFO_LIST",
      hidden:true,
      meta: {
        title: "个人中心",
        hidden: true,
        icon: "icon-xiezilou",
      },
      component: () => import("@views/userinfo/index.vue"),
    },


    
  ],

};

export default userinfoRouter;
