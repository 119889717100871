import { getUserPermission, login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  accessRoutes: [],
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: []

}



const mutations = {
  // 路由
  SET_ACCESS_ROUTES: (state, accessRoutes) => {
    state.accessRoutes = accessRoutes
  },

  // token
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  // name
  SET_NAME: (state, name) => {
    state.name = name
  },
  // 头像
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  // 角色
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }


}

const actions = {

  // 获取用户路由
  getAccessRoutes({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserPermission().then(response => {
        const { data: accessRoutes } = response

        if (!accessRoutes || accessRoutes.length <= 0) {
          reject('用户权限路由: 必须是非空数组!')
        }

        commit('SET_ACCESS_ROUTES', accessRoutes)
        resolve(accessRoutes)
      }).catch(error => {
        reject(error)
      })
    })
  },


  // 用户登录
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 获取用户信息 
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const { roles, name, avatar } = data

        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }
        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 用户退出登录
  logout({ commit, state, dispatch }) {
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    removeToken()
    resetRouter()
    dispatch('tagsView/delAllViews', null, { root: true })
    // return new Promise((resolve, reject) => {
    //   logout(state.token).then(() => {
    //     commit('SET_TOKEN', '')
    //     commit('SET_ROLES', [])
    //     removeToken()
    //     resetRouter()
    //     dispatch('tagsView/delAllViews', null, { root: true })
    //     resolve()
    //   }).catch(error => {
    //     reject(error)
    //   })
    // });

  },

  // 清除Token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
