<!--
 * @Author: Dyf
 * @Date: 2023-05-19 13:39
 * @LastEditors: Dyf
 * @LastEditTime: 2023-07-04 15:03
 * @Descripttion: 
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
@import "./assets/fonts/iconfont.css";
#app{
  min-width: 1700px;
}
</style>
