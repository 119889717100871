import Layout from "@views/layout";

const systemRouter = {
  path: "/system",
  name: "SYSTEM",
  component: Layout,
  redirect: { name: 'SYSTEM_LIST' },
  meta: {
    title: "系统管理",
    icon: "icon-xitong",
    is_menu:true
  },
  children: [
    {
      path: "list",
      name: "SYSTEM_LIST",
      meta: {
        title: "账号管理",
        hidden: true,
        icon: "icon-xitong",
        is_menu:true
      },
      component: () => import("@views/system/index.vue"),
    },
    {
      path: "record",
      name: "SYSTEM_RECORD",
      meta: {
        title: "日志中心",
        icon: "icon-xitong",
        is_menu:true
      },
      component: () => import("@views/system/record.vue"),
    },   
      
    {
      path: "information",
      name: "SYSTEM_INFORMATION",
      meta: {
        title: "消息管理",
        icon: "icon-xitong",
        is_menu:true
      },
      component: () => import("@views/system/information.vue"),
    },   
    {
      path: "role",
      name: "SYSTEM_ROLE",
      meta: {
        title: "角色管理",
        icon: "icon-xitong",
        is_menu:true
      },
      component: () => import("@views/system/role.vue"),
    }, 
    {
      path: "personal",
      name: "SYSTEM_PERSONAL",
      meta: {
        title: "个人中心",
        icon: "icon-xitong",
        is_menu:true
      },
      component: () => import("@views/system/personal.vue"),
    }, 
  ],

};

export default systemRouter;
