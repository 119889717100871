const getters = {
  accessRoutes: state => state.user.accessRoutes,  // 动态路由
  sidebar: state => state.app.sidebar, //侧边栏
  size: state => state.app.size,   //字体大小
  device: state => state.app.device,   
  visitedViews: state => state.tagsView.visitedViews,  //是否暂时tagsView
  cachedViews: state => state.tagsView.cachedViews,   
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes

}
export default getters
