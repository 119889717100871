import Layout from "@views/layout";

const teacherRouter = {
  path: "/teacher",
  name: "TEACHER",
  component: Layout,
  redirect: { name: 'TEACHER_LIST' },
  meta: {
    title: "师资管理",
    icon: "icon-yonghu",
    is_menu:true
  },
  children: [
    {
      path: "list",
      name: "TEACHER_LIST",
      meta: {
        title: "师资管理",
        hidden: true,
        icon: "icon-yonghu",
        is_menu: false,
				prentRoute: "TEACHER"
      },
      component: () => import("@views/teacher/index.vue"),
    },
    {
      path: "info/:id",
      name: "TEACHER_INFO",
      hidden: true,
      meta: {
        title: "师资详情",
        icon: "icon-yonghu",
        is_menu: false,
				prentRoute: "TEACHER"
      },
      component: () => import("@views/teacher/info.vue"),
    },


   
  ],

};

export default teacherRouter;
