import Layout from "@views/layout";

const analysisRouter = {
  path: "/analysis",
  name: "ANALYSIS",
  component: Layout,
  redirect: { name: 'ANALYSIS_LIST' },
  meta: {
    title: "数据分析",
    icon: "icon-tongji",
    is_menu: true 
  },
  children: [
    {
      path: "list",
      name: "ANALYSIS_LIST",
      meta: {
        title: "数据分析",
        hidden: true,
        icon: "icon-tongji",
        is_menu: false,
        prentRoute: "ANALYSIS" 
      },
      component: () => import("@views/analysis/index.vue"),
    },
  ],

};

export default analysisRouter;
