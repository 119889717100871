import API from "@utils/request";
import { PAGE_SIZE } from "@config";
const ROLE = "/education/role/"; //角色管理
const USER = "/education/eduuser/";//账号管理
const MENU = "/education/menu/";//账号管理
/**
 * 会员登录
 * @param {object} params
 */
export async function login(params) {
  try {
    return await API.post(USER + "login", params);
  } catch (error) {
    return error;
  }
}



/**
 * 获取用户列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function getUserList(params) {
  try {

    return await API.get(USER + "list", {
      params: {
        ...params,
        headers: { isLoading: true },
      },
    });
  } catch (error) {
    return error;
  }
}




/**
 * 获取用户详情
 * @param {number} id
 */
export async function accountInfo(id) {
  try {
    return await API.post(USER + "info", {
      id: id,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 获取用户详情
 * @param {number} id
 */
export async function getUserInfo(id) {
  try {
    return await API.post(USER + "info", {
      id: id,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 导出
 * @param {object} param
 */
export async function exportData(param) {
  try {
    return await API.post(USER + "export", param);
  } catch (error) {
    return error;
  }
}

/**
 * 编辑用户信息
 * @param {number} id
 */
export async function accountEdit(params) {
  try {
    return await API.post(USER + "update_data", params);
  } catch (error) {
    return error;
  }
}

/**
 * 编辑用户信息
 * @param {number} id
 */
export async function editUserInfo(params) {
  try {
    return await API.post(USER + "edit", params);
  } catch (error) {
    return error;
  }
}

/**
 * 编辑用户信息
 * @param {number} id
 */
export async function accountAdd(params) {
  try {
    return await API.post(USER + "add", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除用户
 * @param {number} id
 */
export async function accountRemove(id) {
  try {
    return await API.post(USER + "delete", { id: id });
  } catch (error) {
    return error;
  }
}

/**
 * 角色下拉 
 * @param {object} params
 * @returns
 */
export async function roleselect(params) {
  try {
    return await API.post(ROLE + "getkeyval", params);
  } catch (error) {
    return error;
  }
}

/**
 * 修改用户密码
 * @param {number} id
 */
export async function updatePassword(params) {
  try {
    return await API.post(USER + "change_password", params);
  } catch (error) {
    return error;
  }
}


// 用户详情

export async function getInfo(params) {
  // try {
  //   return await API.post(USER + "vue-element-admin/user/info", params);
  // } catch (error) {
  //   return error;
  // }
  return {
    code: '000000',
    data: {
      id: 1, // id
      name: 'admin',  // 前端路由名称
      avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80',
      roles: "管理员"
    },
  }
}


// 退出登录
export async function logout(params) {
  try {
    return await API.post(USER + "/logout", params);
  } catch (error) {
    return error;
  }
}
// 获取json数据
export async function getAreaData(params) {
  try {
    return await API.post(USER + "get_area_data", params);
  } catch (error) {
    return error;
  }
}

// 首页统计
export async function homeTotal() {
  try {
    return await API.get("/education/eduhome/get_home_data");
  } catch (error) {
    return error;
  }
}


/**
 * 获取用户权限
 */
export async function getUserPermission() {

  let course = [
    // 课程管理
    {
      id: 6,
      name: 'COURSE',
      path: '/course',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'COURSE_LIST',
      path: '/course/list',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'COURSE_INFO',
      path: '/course/info',
      permission: ['add', 'edit', 'delete']
    },];
  let resource = [  //资源库
    {
      id: 6,
      name: 'RESOURSE',
      path: '/resource',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'RESOURSE_LIST',
      path: '/resource/list',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'RESOURSE_EDIT',  // 前端路由名称
      path: '/resource/edit',
      permission: ['add', 'edit', 'delete']
    },

  ]

  let school = [  //学校
    {
      id: 6,
      name: 'SCHOOL',  // 前端路由名称
      path: '/school',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'SCHOOL_LIST',  // 前端路由名称
      path: '/school/list',
      permission: ['add', 'edit', 'delete']
    },
  ]

  let analysis = [  //数据分析
    {
      id: 6,
      name: 'ANALYSIS',  // 前端路由名称
      path: '/analysis',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'ANALYSIS_LIST',  // 前端路由名称
      path: '/analysis/list',
      permission: ['add', 'edit', 'delete']
    },
    {
      id: 6,
      name: 'ANALYSIS_LIST',  // 前端路由名称
      path: '/analysis/list',
      permission: ['add', 'edit', 'delete']
    },

  ]

  let teacher = [
    {
      id: 6,
      name: 'TEACHER',  //个人中心
      path: '/teacher',
      permission: ['add', 'edit', 'delete']
    },

    {
      id: 6,
      name: 'TEACHER_LIST',  //个人中心
      path: '/teacher/list',
      permission: ['add', 'edit', 'delete']
    },

    {
      id: 6,
      name: 'TEACHER_INFO',  //个人中心
      path: '/teacher/info',
      permission: ['add', 'edit', 'delete']
    },

  ]

  //   // 学生管理

  let student = [
    {
      id: 6,
      name: 'STUDENT',  //个人中心
      path: '/student',
      permission: ['add', 'edit', 'delete']
    },

    {
      id: 6,
      name: 'STUDENT_LIST',  //个人中心
      path: '/student/list',
      permission: ['add', 'edit', 'delete']
    },

    {
      id: 6,
      name: 'STUDENT_INFO',  //个人中心
      path: '/student/info',
      permission: ['add', 'edit', 'delete']
    },
  ]

  let system = [
    {
      id: 6,
      name: 'SYSTEM',  //个人中心
      path: '/system',
      permission: ['add', 'edit', 'delete']
    },
  ]




  //   const menu = [
  //     // 课程管理
  //     {
  //     id: 6,
  //     name: 'COURSE',
  //     path: '/course',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'COURSE_LIST',
  //   //   path: '/course/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'COURSE_INFO',
  //   //   path: '/course/info',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 资源库
  //   {
  //     id: 6,
  //     name: 'RESOURSE',
  //     path: '/resource',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'RESOURSE_LIST',
  //   //   path: '/resource/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'RESOURSE_EDIT',  // 前端路由名称
  //   //   path: '/resource/edit',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  // // 学校

  //   {
  //     id: 6,
  //     name: 'SCHOOL',  // 前端路由名称
  //     path: '/school',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'SCHOOL_LIST',  // 前端路由名称
  //   //   path: '/school/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 数据分析
  //   {
  //     id: 6,
  //     name: 'ANALYSIS',  // 前端路由名称
  //     path: '/analysis',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'ANALYSIS_LIST',  // 前端路由名称
  //   //   path: '/analysis/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'ANALYSIS_LIST',  // 前端路由名称
  //   //   path: '/analysis/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },


  //   // {
  //   //   id: 6,
  //   //   name: 'USERINFO',   //个人中心
  //   //   path: '/userinfo', 
  //   //   permission: ['add', 'edit','delete'] 
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'USERINFO_LIST',  //个人中心
  //   //   path: '/userinfo/list', 
  //   //   permission: ['add', 'edit','delete'] 
  //   // },

  //   // 师资管理teacher
  //   {
  //     id: 6,
  //     name: 'TEACHER',  //个人中心
  //     path: '/teacher',
  //     permission: ['add', 'edit', 'delete']
  //   },

  //   // {
  //   //   id: 6,
  //   //   name: 'TEACHER_LIST',  //个人中心
  //   //   path: '/teacher/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // {
  //   //   id: 6,
  //   //   name: 'TEACHER_INFO',  //个人中心
  //   //   path: '/teacher/info',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 学生管理
  //   {
  //     id: 6,
  //     name: 'STUDENT',  //个人中心
  //     path: '/student',
  //     permission: ['add', 'edit', 'delete']
  //   },

  //   // {
  //   //   id: 6,
  //   //   name: 'STUDENT_LIST',  //个人中心
  //   //   path: '/student/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // {
  //   //   id: 6,
  //   //   name: 'STUDENT_INFO',  //个人中心
  //   //   path: '/student/info',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 系统管理
  //   {
  //     id: 6,
  //     name: 'SYSTEM',  //个人中心
  //     path: '/system',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_LIST',  //个人中心
  //   //   path: '/system/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_RECORD',  //个人中心
  //   //   path: '/system/record',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_SYBSTANCE',  //内容管理
  //   //   path: '/system/substance',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_INFORMATION',  //内容管理
  //   //   path: '/system/information',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_ROLE',  //内容管理
  //   //   path: '/system/role',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_PERSONAL',  //内容管理
  //   //   path: '/system/personal',
  //   //   permission: ['add', 'edit', 'delete']
  //   // }
  // ];


  



  try {
    const userMenuArr = await API.post(MENU + "getusermenu", {});
    
    const menu = [];

    // const mergeResult = [...array1, ...array2];

    userMenuArr.data.forEach((item1) => {
      if (item1.amenu_router_name == 'HOME') {
        menu.push({ id: item1.amenu_id, name: item1.amenu_router_name, path: item1.amenu_address, permission: [] });
      }


      //课程管理
      if (item1.amenu_router_name == 'COURSE_LIST') {
        menu.push(...course)
      }

      // 资源库
      if (item1.amenu_router_name == 'RESOURSE_LIST') {
        menu.push(...resource)
      }

      // 学校

      if (item1.amenu_router_name == 'SCHOOL_LIST') {
        menu.push(...school)
      }

      // 数据分析

      if (item1.amenu_router_name == 'ANALYSIS_LIST') {
        menu.push(...analysis)
      }


      // 师资管理

      //"TEACHER_LIST"

      if (item1.amenu_router_name == 'TEACHER_LIST') {       
        menu.push(...teacher)
      }


      // 学生管理

      if (item1.amenu_router_name == 'STUDENT_LIST') {
        menu.push(...student)
      }


      // 学生管理



      // 系统设置

      if (item1.amenu_router_name == "SYSTEM") {
       
        item1.children.forEach((item) => {
          
          if (item.amenu_router_name == 'SYSTEM_LIST' || item.amenu_router_name == 'SYSTEM_RECORD' || item.amenu_router_name == 'SYSTEM_SYBSTANCE' || item.amenu_router_name == 'SYSTEM_INFORMATION' || item.amenu_router_name == 'SYSTEM_ROLE' || item.amenu_router_name == 'SYSTEM_PERSONAL') {
            menu.push(...system,
              { id: item.amenu_id, name: item.amenu_router_name, path: item.amenu_address, permission: [] } 
            )
          }
        })
      }






    })
    
    return {
      code: '000000',
      data: menu
    }

  } catch (error) {
    return error;
  }
  // 以下数据为，模拟接口返回权限数据格式
  // return {
  //   code: '000000',
  //   data: menu
  // }

}


