import Layout from "@views/layout";

const resourceRouter = {
  path: "/resource",
  name: "RESOURSE",
  component: Layout,
  redirect: { name: 'RESOURSE_LIST' },
  meta: {
    title: "资源库",
    icon: "icon-yingyong",
    is_menu: true
		
  },
  children: [
    {
      path: "list",
      name: "RESOURSE_LIST",
      meta: {
        title: "资源库概况",
        keepAlive: false,
        icon: "icon-yingyong",
        is_menu: false,
				prentRoute: "RESOURSE"
      },
      component: () => import("@views/resource/index.vue"),
    },
  ],

};

export default resourceRouter;
