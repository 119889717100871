import API from "@utils/request";

const COMMON = "/common/";

/**
 * 文件上传
 * @param {file} file
 */
export async function uploadFile(file) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return await API.post(COMMON + "/uploadfile", formData, {
      transformRequest: [
        function (data) {
          return data;
        },
      ],
    });
  } catch (error) {
    return error;
  }
}

/**
 * 文件上传
 * @param {file} file
 */
export async function uploadImg(file) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return await API.post(COMMON + "/uploadfile", formData, {
      transformRequest: [
        function (data) {
          return data;
        },
      ],
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion:获取操作手册 
 * @Author: Dyf
 * @param {number} dgi_code 获取端口：TEACHER=教师端，EDUCATION=教育局端，SCHOOL=学校端，STUDENT=学生端，PARENT=家长端
 * @return {*}
 */
export async function $get_manual(dgi_code) {
  try {
    return await API.post("/protal/tool/get_manual", {
      dgi_code
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取省市区数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getProvinceData() {
  try {
    return await API.post("/protal/common/get_province_city_area");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取年级数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getGrade() {
  try {
    return await API.post("/protal/common/grade_data");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目教材数据（树）
 * @Author: Dyf
 * @param {number} grade_id 年级编码
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $getEditionTree(grade_id, scsch_id) {
  try {
    return await API.post("/protal/common/edition_data", {
      grade_id,
      scsch_id,
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 省市区下的学校
 * @Author: Dyf
 * @param {Number} params.scsch_province 省
 * @param {Number} params.scsch_city 市
 * @param {Number} params.scsch_area 区
 * @return {*}
 */
export async function $getSchool(params) {
  try {
    return await API.post("/protal/common/get_school", params);
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 获取学校下的班级（树）
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $getGradeClass(params) {
  try {
    return await API.post("/protal/common/get_school_class", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传图片
 * @Author: Dyf
 * @param {file} file 图片文件
 * @return {*}
 */
export async function $uploadImg(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  try {
    return await API.post("/protal/tool/upLoadimage", formData);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取题型数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getQuestionType() {
  try {
    return await API.get("/protal/tool/question_type");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getSubject() {
  try {
    return await API.get("/protal/tool/subject");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取所有教材版本
 * @Author: Dyf
 * @return {*}
 */
export async function $getAllTextbook() {
  try {
    return await API.get("/protal/tool/edition");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 班级列表
 * @Author: Dyf
 * @return {*}
 */
export async function $getClass() {
  try {
    return await API.post("/teacher/schclass/get_class_list");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 筛选章节信息
 * @Author: Dyf
 * @param {number} params.grade 年级编码
 * @param {number} params.sysub_id 科目id
 * @param {number} params.sysub_idsyedi_id 教材版本id
 * @param {number} params.sysub_idsyedi_version 教材版号
 * @param {number} params.sccou_cha_pid 章id (查询节)
 * @param {string} params.chapter_word 章节关键字
 * @return {*}
 */
export async function $getChapters(params) {
  try {
    return await API.post("/protal/courseschapter/get_chapter", params);
  } catch (error) {
    return error;
  }
}

/******* 
 * @Descripttion: 获取手机验证码
 * @Author: Dyf
 * @param {string} phone 手机号码
 * @return {*}
 */
export async function $getCode(phone) {
  try {
    return await API.post("/protal/common/get_smscaptcha", {phone});
  } catch (error) {
    return error;
  }
}


/** 导出-get */
// export async function 方法名(params) {
//   try {
//     return await API.get("接口名称",{
//       params,
//       responseType: "blob",
//     });
//   } catch (error) {
//     return error;
//   }
// }

/** 导出-post */
// export async function 方法名(params) {
//   try {
//     return await API.post("接口名称", params, {
//       responseType: "blob",
//     });
//   } catch (error) {
//     return error;
//   }
// }
