import Vue from "vue";

import "normalize.css/normalize.css"; // 替代CSS重置

import "@utils/element-ues"; // 按需引入ElementUI,若有缺少，自行添加

import Element from 'element-ui'

import "@assets/styles/index.scss"; // 全局引用样式
import { API_ROOT } from "@config";

// import './assets/fonts/iconfont.css'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;


import '@/icons'  //引入icons
import VueI18n from 'vue-i18n'  //引入i18n
import enUS from '@utils/lang/en-US.js';        // 英文语言文件
import zhCN from '@utils/lang/zh-CN.js';        // 中文语言文件

import Cookies from 'js-cookie'


import App from "./App.vue";
import router from "./router";
import store from "./store";

import './permission' // 权限控制
import _ from "lodash"; // JavaScript 实用工具库
import * as filters from "@/filters"; // 全局过滤器
import * as directives from "./directives"; // 全局过指令
// import * as echarts from "echarts"; //图表

//百度编辑器
import "../lib/ueditor/ueditor.config.js";
import "../lib/ueditor/ueditor.all.min.js";
import "../lib/ueditor/lang/zh-cn/zh-cn.js";
import "../lib/ueditor/ueditor.parse.min.js";

/* 判断数据是否存在、是否为空（空对象 || 空数组） */
Vue.prototype.$isEmpty = function (data) {
  if (!data) return true;
  return (
    (data instanceof Array && !data.length) ||
    Object.keys(data).length == 0 ||
    data + "" == ""
  );
};

/* 上传配置 */
Vue.prototype.$upload = {
  header: { accessToken: sessionStorage.getItem("token") },
  imgAction: `${API_ROOT}/protal/tool/upLoadimage`, // 图片上传地址
  fileAction: `${API_ROOT}/protal/tool/uploadFile`, // 文件上传地址
};

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
})

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh-CN',   //默认语言
  messages: {
       'en-US': enUS,
       'zh-CN': zhCN
  }
})
Vue.prototype._i18n = i18n;
// lodash挂载
Vue.prototype._=_;
// Vue.prototype.$echarts = echarts; // 全局引用图表



/* 引入自定义组件库 */
import tinymce from "tinymce";
import VueTinymce from "@packy-tang/vue-tinymce";
Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);
const files = require.context("./components/try", true, /\.vue$/);
files.keys().forEach((routerPath) => {
  const componentName = routerPath.replace(/^\.\/(.*)\/index\.\w+$/, "$1");
  if (componentName.indexOf("/") === -1) {
    const value = files(routerPath);
    Vue.component(
      "try" + componentName.replace(/([A-Z])/g, "-$1").toLowerCase(),
      value.default
    );
  }
}, {});

// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 注册全局过指令
Object.keys(directives).forEach((key) => {
  Vue.use(directives[key]);
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


/* 文件链接下载 */
Vue.prototype.$fileDownload = (url) => {
  let name = url.split("/");
  name = name[name.length - 1].split(".")[0];
  // 创建一个a标签
  let link = document.createElement("a");
  // 给a标签设置id
  link.id = "downloadLink";
  // 将a标签隐藏
  link.style.display = "none";
  // 给a标签添加下载链接
  link.href = url;
  // 给a标签添加一个download属性
  link.setAttribute("download", name);
  // 将创建的a标签加入到body的尾部
  document.body.appendChild(link);
  // 执行a标签
  link.click();
  // 获取a标签dom
  let node = document.getElementById("downloadLink");
  // 删除对应dom节点
  document.body.removeChild(node);
};

