<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
     

        <!-- <el-button type="primary" icon="el-icon-edit" circle @click="handellanguage"></el-button> -->

      </template>

      <div class="user-info">
        <!-- <el-badge is-dot class="item">
          <div>
            <i class="iconfont">&#xe652;</i>
          </div>
        </el-badge> -->

        <div class="user-close">
          <a @click="handellogout">
          <div class="user-close-img" :style="{ background: theme }">
            <i class="iconfont">&#xe628; </i>
           
          </div>
        </a>
          <!-- <el-button class="user-close-btn" type="text" @click="handellogout">退出账号</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";

export default {
  data() {
    return {
      isLanguage: true,
    };
  },
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
  },
  computed: {
    ...mapGetters([
      "sidebar",
      "avatar",
      // 'name',
      "device",
    ]),
    i18n() {
      return this.$t("index");
    },
    theme() {
      return this.$store.state.settings.theme;
    },
  },

  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    
    async handellogout() {
      sessionStorage.clear();
      localStorage.clear();
      await this.$store.dispatch("user/logout");
      location.reload();
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      // this.$router.push(`/login`);

    },
    handellanguage() {
      switch (this._i18n.locale) {
        case "zh-CN":
          this.isLanguage = false;
          this._i18n.locale = "en-US";
          break;
        default:
          this.isLanguage = true;
          this._i18n.locale = "zh-CN";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 92px;
  overflow: hidden; //设置overflow: hidden不显示sidebar展开
  position: relative;
  background: #e8eaf3;
  position: relative;
  margin: 0 40px;
  border-bottom: 1px solid #E1E3EC;

  .hamburger-container {
    line-height: 124px;
    height: 100%;
    float: left;
    position: absolute;
    left: -42px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 92px;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .right-language {
      cursor: pointer;
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 20px;
      color: #ccc;
      vertical-align: text-bottom;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .item {
      height: 44px;
      line-height: 44px;
      padding-left: 6px;
      .info-img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
      div {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        text-align: center;
        line-height: 44px;
        background: #ffd25a;
        cursor: pointer;
        i {
          color: #313131;
        }
      }
    }
    .user-close {
      margin-left: 28px;
      padding-left: 28px;
      height: 60px;
      display: flex;
      align-items: center;
    }
  }
}
.user-info {
  position: relative;
  display: flex;
  align-items: center;
  .user-close-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    // background: #6c4ecb;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
    color: #ffffff;
    i {
      font-size: 18px;
    }
  }
  .user-close-btn {
    margin-left: 13px;
    color: #262626;
  }
}
.user-close::after {
  content: "";
  height: 18px;
  width: 1px;
  background: #cbccd4;
  position: absolute;
  left: 76px;
  top: 20px;
}
::v-deep {
  .el-badge__content.is-dot {
    width: 12px;
    height: 12px;
  }
  .el-badge__content.is-fixed.is-dot {
    right: 11px;
  }
  .el-badge__content.is-fixed {
    top: 7px;
  }
}
</style>
