import { asyncRoutes, constantRoutes } from '@/router'


// asyncRoutes  动态路由   constantRoutes   静态路由
/**
 * 判断用户是否有权限
 * @param dynamicRoutes  动态路由
 * @param route
 */



function hasPermission(dynamicRoutes, route) {



  if (dynamicRoutes.find(dynamicRoute => dynamicRoute.name == route.name) || route.path == '*') {
    return true
  }
  else {
    return false
  }

}

/**
 * 通过递归过滤异步路由表
 * @param asyncRoutes   需要异步的路由
 * @param dynamicRoutes 动态获取的路由
 */
export function filterAsyncRoutes(asyncRoutes, dynamicRoutes) {

  const res = []
  asyncRoutes.forEach(route => {
    const tmp = { ...route }

    if (hasPermission(dynamicRoutes, route)) {


      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, dynamicRoutes)
      }
      res.push(tmp)
    }
  });


  return res
}

const state = {
  routes: [],
  addRoutes: [],
}

const mutations = {
  
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)

  }
}

const actions = {
  
  generateRoutes({ commit }, dynamicRoutes) {
    


    return new Promise(resolve => {
      let accessedRoutes
      accessedRoutes = filterAsyncRoutes(asyncRoutes, dynamicRoutes);
      commit('SET_ROUTES', accessedRoutes)
      
      resolve(accessedRoutes);

    })

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
