import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import getPageTitle from "@/utils/get-page-title";

// vue中页面加载顶部进度条效果的实现
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration


const whiteList = ["/login",'/forgot']; // 不需要重定向的白名单


router.beforeEach(async (to, from, next) => {
  NProgress.start()

  // if (to.matched.length == 0) {
  //   next({ path: '/404' })
  // }

  // 设置页面标题
  document.title = getPageTitle(to.meta.title);

  // 判断用户是否已经登录
  const hasToken = sessionStorage.getItem("Token");
  // 判断是否需要修改密码
  const updatePwd = sessionStorage.getItem("updatePwd");
  if (hasToken) {
    //跳转去修改密码
    if(updatePwd&&!(to.path == "/system/personal"||to.path == "/login")){
      Message.warning("为了您的账户安全，请修改密码");
      next({ name: "SYSTEM_PERSONAL" });
    }else if (to.path === "/login") {
      // 如果已登录，则重定向到主页
      next({ path: "/" });
      NProgress.done();
    } else {
      // 判断是否已获取到动态路由
      const hasAccessRoutes = store.getters.accessRoutes && store.getters.accessRoutes.length > 0;
  
      if (hasAccessRoutes) {
        next();
      } else {
        try {

          const { roles } = await store.dispatch('user/getInfo')
          // 获取用户路由
          const accessRoutes = await store.dispatch("user/getAccessRoutes",roles);

          // 根据角色生成可访问的路由图
          const addRoutes = await store.dispatch(
            "permission/generateRoutes",
            accessRoutes
          );
        
          // 动态添加可访问的路由
          addRoutes.forEach(route => {
            router.addRoute(route);
          });

          // hack方法，以确保地址路由是完整的
          // 设置replace: true，这样导航就不会留下历史记录
          next({ ...to, replace: true });
        } catch (error) {
          // 删除令牌并转到登录页面重新登录
          sessionStorage.clear();
          Message.error(error || "有错误");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* 没有令牌*/

    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单，直接去
      next();
    } else {
      // 其他没有访问权限的页面被重定向到登录页面。
      next(`/login?redirect=${to.path}`);
      NProgress.done()
    }
  }
});

router.afterEach(() => {
  NProgress.done()
 });
