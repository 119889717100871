module.exports={
    index:{
        语言:'language',
        刷新:'refresh',
        关闭:'close',
        首页:"home",
        信息:"information",
        信息列表:"Information list",
        编辑信息:"Edit information",
        用户:"user",
        用户列表:"User list",
        用户编辑:"User edit",
        课程:"curriculum",
        课程管理:"course management",
        课程信息:"Course information",
        校内课程:"On-site courses",
        系统管理:"system management",
        管理员管理:"Administrator management"
    }
}