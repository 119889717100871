<template>
    <div>
        <el-cascader size="large" :options="areaRegionData" v-model="tValue"  :props="props" :disabled="isShow" style="width:250px" clearable filterable>
        </el-cascader>
    </div>
</template>

<script>

import { regionData } from "element-china-area-data";


export default {
    name: "tryDistrict",
    components: {
    },
    props: {
        // 绑定的值
        value: {
            type: [Array, Object,String],
            default: undefined
        },
        isShow: {//是否为显示状态
            type: Boolean,
            default: false
        },

        isSearch: {//是否为单选搜索
            type: Boolean,
            default: false
        }


    },
    data() {
        return {
            areaRegionData: regionData,
            tValue: eval(this.value),
            props:{ 
                checkStrictly: this.isSearch,
                label:"label",
                value:"label",
                children:"children"
             },
        };
    },
    watch: {
        tValue: function (newValue) {
            // 修改调用者传入的值
            this.$emit('change', newValue)
        },
        value(n, o) {
            this.tValue = n;
        }

    },
    mounted() {
        
    },
    methods: {

    },
};
</script>