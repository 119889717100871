import Layout from "@views/layout";

const studentRouter = {
  path: "/student",
  name: "STUDENT",
  component: Layout,
  redirect: { name: 'STUDENT_LIST' },
  meta: {
    title: "学生管理",
    icon: "icon-xuesheng",
    is_menu:true
  },
  children: [
    {
      path: "list",
      name: "STUDENT_LIST",
      meta: {
        title: "学生管理",
        hidden: true,
        icon: "icon-xuesheng",
        is_menu: false,
				prentRoute: "STUDENT"
      },
      component: () => import("@views/student/index.vue"),
    },
    {
      path: "info/:id",
      name: "STUDENT_INFO",
      hidden: true,
      meta: {
        title: "学生详情",
      
        icon: "icon-xuesheng",
        is_menu: false,
				prentRoute: "STUDENT"
      },
      component: () => import("@views/student/info.vue"),
    },


   
  ],

};

export default studentRouter;
