<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  height: calc(100% - 105px);
  padding: 42px 42px 20px;
  box-sizing: border-box;
  background: #e8eaf3;
  width: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

.fixed-header + .app-main {
  min-height: calc(100vh - 0px);
  padding-top: 100px;
}

.hasTagsView {
  .app-main {
    min-height: calc(100vh - 151px);
  }

  .fixed-header + .app-main {
    padding-top: 135px;
  }
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
