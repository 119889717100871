<template>
  <div>
    <el-dialog :visible.sync="mVisible" :title="title" :modal="modal" :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody" :lock-scroll="lockScroll" :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape" :show-close="false" :width="width" :fullscreen="mFullscreen"
      :custom-class="customClass" :top="top" :before-close="beforeClose" :center="center"
      :destroy-on-close="destroyOnClose" @open="handleOpen" @opend="handleOpend" @close="handleClose"
      @closed="handleClosed">
      <div slot="title" class="el-dialog__header" style="border: none;padding: 0;">
        <slot name="title">
          <span class="el-dialog__title">{{ title }}</span>
        </slot>
        <button type="button" class="el-dialog__headerfullbtn" aria-label="Fullscreen"
          @click="() => { mFullscreen = !mFullscreen }">
          <el-tooltip :enterable="false" effect="dark" :content="mFullscreen ? '还原' : '最大化'" placement="bottom">
            <i :class="mFullscreen ? 'el-icon-copy-document' : 'el-icon-full-screen'"></i>
          </el-tooltip>
        </button>
        <button type="button" class="el-dialog__headerbtn" aria-label="Close" v-if="showClose" @click="handleClose">
          <el-tooltip effect="dark" content="关闭" placement="bottom">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </el-tooltip>
        </button>
      </div>
      <slot></slot>
      <slot slot="footer" name="footer" style="text-align: center;"></slot>
    </el-dialog>
    <div v-show="visible" class="v-modal" style="z-index: 1000;position:absolute">
    </div>
  </div>
</template>
<script>
import { number } from 'echarts'

export default {
  name: 'MDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },

    modal: {
      type: Boolean,
      default: false
    },

    modalAppendToBody: {
      type: Boolean,
      default: false
    },

    appendToBody: {
      type: Boolean,
      default: true
    },

    lockScroll: {
      type: Boolean,
      default: true
    },

    closeOnClickModal: {
      type: Boolean,
      default: true
    },

    closeOnPressEscape: {
      type: Boolean,
      default: true
    },

    showClose: {
      type: Boolean,
      default: true
    },

    width: {
      type: String,
      default: undefined
    },

    fullscreen: Boolean,

    customClass: {
      type: String,
      default: ''
    },

    top: {
      type: String,
      default: '15vh'
    },
    beforeClose: {
      type: Function,
      default: undefined
    },
    center: {
      type: Boolean,
      default: false
    },

    destroyOnClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mVisible: false,
      mFullscreen: this.fullscreen
    }
  },
  watch: {
    visible(n) {
      this.mVisible = n
      if (!n) {
        this.$emit('update:visible', false)
        this.$emit('close')
      }
    },
    mFullscreen(n, o) {
      this.$emit('update:fullscreen', n)
    },
    fullscreen(n) {
      this.mFullscreen = n;
    }
  },
  methods: {
    handleOpen() {
      this.$emit('open')
    },
    handleOpend() {
      this.$emit('opend')
    },
    handleClose() {
      this.$emit('close')
    },
    handleClosed() {
      this.$emit('closed')
    }
  }
}
</script>
<style scoped>
.el-dialog__headerfullbtn {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  width: 64px;
}
/deep/.el-dialog{
  border-radius: 20px !important;
}
</style>
