<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />

    <div class="menu-list">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
        <a class="openpdf" :href="dgi_value" target="_blank">
            <i class="iconfont">&#xe603;</i>
            <span>操作文档</span>
          </a>
      </el-menu>
    </div>
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar> -->
  </div>
</template>

<script>
import { $get_manual } from "@api/common";
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(["permission_routes", "sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  data() {
    return {
      dgi_value:""
    }
  },
  mounted() {
    this.get_manual();
  },
  methods: {
    /** 获取操作手册 */
    async get_manual() {
      let { data } = await $get_manual({dgi_code:"EDUCATION"});
      this.dgi_value=data.dgi_value
    },
  }
};
</script>

<style lang="scss" scoped>
  .menu-list{
    margin-top: 80px;
  }
  .openpdf{
    line-height: 56px;
    font-size: 14px;
    color: #303133;
    list-style: none;
    cursor: pointer;
    position: relative;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    white-space: nowrap;
    padding: 10px 38px !important;
    box-sizing: border-box;
    display: flex !important;
    align-items: center;
    &:hover{
      background-color: #6C4ECB;
      color: #FFFFFF;
    }
    i{
      font-size: 22px;
      width: 41px;
      height: 41px;
      line-height: 41px;
      border-radius: 10px;
      color: #cbc4e1;
      text-align: center;
      display: inline-block;
      margin-right: 12px;
      margin-left: -2px;
    }
  }
</style>
