<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" :to="'/system/personal'">
        <div class="sidebar-logo-box">
          <img v-if="logo" :src="logo" class="sidebar-logo" />
        </div>
      </router-link>

      <router-link v-else key="expand" class="sidebar-logo-link" :to="'/system/personal'">
        <div class="sidebar-logo-box">
          <img v-if="logo" :src="logo" class="sidebar-logo" />
        </div>
        <h1 class="sidebar-title">{{ title }}</h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import { formatImg } from "@utils";
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "智能教育系统",
      logo: require("../../../../assets/images/avarat.png"),
    };
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  created() {
    this.init();
    
  },methods:{
    init(){
      const userinfo = JSON.parse(sessionStorage.getItem("Userinfo"));
      if(userinfo != undefined){
          this.title=userinfo.eduser_name;
          if(userinfo.eduser_image){
            this.logo =this.formatImage(userinfo.eduser_image);
          }
          
          
      }
    },
    formatImage(url) {
      return formatImg(url);
    },
  }
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
.sidebar-logo-box{
  width: 65px;
  height: 65px;
  margin: 0 auto;
}
.sidebar-logo-box::after{
      content:'';
      width: 12px;
      height: 12px;
      background-color: #1fccb6;
      position: absolute;
      right: 76px;
      bottom: 44px;
      border-radius: 50%;
    }

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 102px;
  margin-top: 55px;
  background: #ffffff;
  color: #0e0e0e;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 12px;
      position: relative;
    }
    

    & .sidebar-title {
      margin: 0;
      color: #0e0e0e;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
      position: relative;
    }
  

  }
}
</style>
