<template>
  <div v-if="!item.hidden">
    <template v-if="
      hasOneShowingChild(item.children, item) &&
      (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
      !item.alwaysShow
    ">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
          <i class="sub-el-icon iconfont" :class="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"></i>
          <span data-v-321df034="">{{
            i18n[onlyOneChild.meta.title] || onlyOneChild.meta.title
          }}</span>
        </el-menu-item>
      </app-link>
    </template>




    <template v-else>

      <!-- <div class="el-menu-item">
        <i
            class="sub-el-icon iconfont"
            :class="item.meta.icon || (item.meta && item.meta.icon)"
          ></i>
        <span>{{
            i18n[item.meta.title] || item.meta.title
          }}</span>

      </div> -->
      <el-menu-item :index="resolvePath(item.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
        <i class="sub-el-icon iconfont" :class="item.meta.icon || (item.meta && item.meta.icon)"></i>
        <span>{{
          i18n[item.meta.title] || item.meta.title
        }}</span>

        <div class="menu-display">

          <div class="menu-display-box">
            <app-link v-for="child in item.children" :to="resolvePath(child.path)" :key="child.path">
            <el-menu-item :index="resolvePath(child.path)">
              <span data-v-321df034="">{{
                i18n[child.meta.title] || child.meta.title
              }}</span>
            </el-menu-item>
          </app-link>
          </div>



        </div>
      </el-menu-item>

    </template>


  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";

export default {
  name: "SidebarItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {
      menuActiveBg: "",
    };
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
    menuActiveText() {
      return this.$store.state.settings.menuActiveText;
    },
  },

  // mounted() {
  //   this.menuActiveBg = this.$store.state.settings.menuActiveText;
  // },

  // watch: {
  //   menuActiveText(val) {
  //     document.querySelector(".is-active > .sub-el-icon").style.background = val;
  //   },
  // },

  methods: {

    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },


};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

::v-deep {
  .el-submenu .el-menu-item {
    height: 70px;
    display: flex;
  }

  .el-menu-item {
    padding: 10px 38px !important;
    box-sizing: border-box;
    height: 70px;
    color: #3a3a3a;
    display: flex;
    align-items: center;

    i {
      font-size: 22px;
      width: 41px;
      height: 41px;
      line-height: 41px;
      border-radius: 10px;
      color: #cbc4e1;
      text-align: center;
    }

    span {
      flex: 1;
      overflow: hidden;
    }
  }

  .el-menu-item {
    position: relative;

  }
  .menu-display-box{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: #6C4DCC;
    position: relative;
  }
  .menu-display-box::after{
    content: '';
    position: absolute; 
    left: -10px; 
    bottom: 65px;
    transform: translateY(-50%);
    border-top: 6px solid transparent; 
    border-bottom: 6px solid transparent; 
    border-right: 10px solid #6C4DCC;
  }

  .el-menu-item .menu-display {
    width: 230px;
    padding: 20px 30px;
    box-sizing: border-box;
    background: transparent;
    display: none;
    position: absolute;
    right: -220px;
    bottom: -60px;
  }

  .menu-display .el-menu-item {
    background: #6C4DCC !important;
    color: #c5b5f5 !important;
    border-radius: 10%;
    
  }

  .menu-display .el-menu-item:hover {
    color: #ffffff !important;
  }

  .menu-display .el-menu-item i {
    width: 0;
    color: #6C4DCC;
  }
  .menu-display  .el-menu-item span{
    text-align: center;
    border-bottom: 1px solid #7458CD;
  }
  .menu-display a:last-child .el-menu-item span{
    border: none;
  }

  .el-menu-item:hover .menu-display {
    display: block;
  }

 
  .is-active>.sub-el-icon {
    font-size: 22px;
    background: $menuActiveText;
    color: #ffffff !important;
  }

  .el-menu-item.is-active {
    position: relative;
  }

  .el-menu-item::after {
    content: '';
    width: 5px;
    height: 0px;
    background-color: #fff;
    border-radius: 2px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.6s;
  }




  .el-menu-item.is-active::after {
    content: '';
    width: 5px;
    height: 33px;
    background-color: #6c4ecb;
    border-radius: 2px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .el-menu-item:hover::after{
    content: '';
    width: 5px;
    height: 33px;
    background-color: #6c4ecb;
    border-radius: 2px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }


  .el-submenu__title {
    padding: 10px 38px !important;
    box-sizing: border-box;
    height: 70px;
    line-height: 70px;
    color: #3a3a3a;
    display: flex;
    align-items: center;

    i {
      font-size: 22px;
      width: 41px;
      height: 41px;
      line-height: 41px;
      border-radius: 10px;
      color: #cbc4e1;
      text-align: center;
    }

    .el-submenu__icon-arrow {
      width: 20px;
      height: 20px;
      line-height: 14px;
    }
  }
}
</style>
