import Layout from "@views/layout";

const schoolRouter = {
  path: "/school",
  name: "SCHOOL",
  component: Layout,
  redirect: { name: 'SCHOOL_LIST' },
  meta: {
    title: "学校管理",
    icon: "icon-xiezilou",
    is_menu:true
  },
  children: [
    {
      path: "list",
      name: "SCHOOL_LIST",
      meta: {
        title: "学校管理",
        hidden: true,
        icon: "icon-xiezilou",
        is_menu: false,
				prentRoute: "SCHOOL"
      },
      component: () => import("@views/school/index.vue"),
    },

    {
      path: "edit",
      name: "SCHOOL_EDIT",
      hidden: false,
      meta: {
        title: "用户编辑",
        icon: "icon-xiezilou",
        is_menu: false,
				prentRoute: "SCHOOL"
      },
      component: () => import("@views/school/edit.vue"),

    },

    {
      path: "details",
      name: "SCHOOL_DETAILS",
      hidden: false,
      meta: {
        title: "用户详情",
        is_menu: false,
				prentRoute: "SCHOOL"
      },
      component: () => import("@views/school/details.vue"),

    },
  ],

};

export default schoolRouter;
