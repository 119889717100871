import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* 布局 */
import Layout from "@views/layout";

/*
 * 路由器模块
 */
import schoolRouter from "./modules/school";
import resourceRouter from "./modules/resource";
import courseRouter from "./modules/course";
import analysisRouter from "./modules/analysis"; //数据分析
import userinfoRouter from "./modules/userinfo"; //个人中心
import teacherRouter from "./modules/teacher"; //师资管理
import studentRouter from "./modules/student"; //师资管理
import systemRouter from "./modules/system"; //师资管理


/**
 * 注意:子菜单只在路由 children.length >= 1 时，才出现
 *
 * hidden: true                   如果设置为true，项目将不会显示在侧边栏中（默认为false）
 * alwaysShow: true               如果设置为true，将始终显示根菜单（默认为false）
 *                                如果没有设置alwaysShow，当item有多个子路由时，它将变成嵌套模式，否则不显示根菜单
 * redirect: noRedirect           如果设置noRedirect，则不会在面包屑中重定向
 * name:'router-name'             使用 <keep-alive> （必须设置!），字母全部大写，多个单词以“_”分隔，例：TEST_ONE （推荐全部设置）
 * meta : {
    permission: ['add','edit']   该路由下，按钮的权限，该值一般从接口返回（可以设置多个权限）
    title: 'title'               名称显示在侧边栏和面包屑中（推荐全部设置）
    icon: 'el-icon-x'            图标显示在侧栏中
    keepAlive: true              如果设置为true，页面将不会被缓存（默认为false）
    breadcrumb: false            如果设置为false，该项将隐藏在breadcrumb中（默认为true）
    activeMenu: 'router-name'    如果设置路径，侧边栏将突出显示您设置的路径名
  }
 */

/**
 * 路由白名单
 * 没有权限要求的页面
 * 所有角色都可以被访问
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: "/login",
    name: 'LOGIN',
    hidden: true,
    component: () => import("@views/login/index"),
  },
  {
    path: "/forgot",
    name: 'FORGOT',
    hidden: true,
    component: () => import("@views/login/forgot.vue"),
  },
  {
    path: "/404",
    name: '404',
    hidden: true,
    component: () => import("@views/error/404"),
  },
  {
    path: "/401",
    name: '401',
    hidden: true,
    component: () => import("@views/error/401"),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'HOME',
        alwaysShow: true,
        meta: { title: '首页', icon: "icon-gongyingshangshouye", affix: true },
        component: () => import('@/views/home/index')
      }
    ]
  },
];

/**
 * 动态路由
 * 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [
  /**
   * 路由模块  
   **/
  courseRouter,
  resourceRouter,
  schoolRouter,
  analysisRouter,
  teacherRouter,
  userinfoRouter,
  studentRouter,
  systemRouter,
  // 所有页面都未匹配到时
  { path: "*", hidden: true, redirect: { name: '404' }, },

];

const createRouter = () =>
  new Router({
    // mode: 'history', // 需要服务器支持
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();


// 详情见: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // 重置路由器
}

export default router;



//   const menu = [
  //     // 课程管理
  //     {
  //     id: 6,
  //     name: 'COURSE',
  //     path: '/course',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'COURSE_LIST',
  //   //   path: '/course/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'COURSE_INFO',
  //   //   path: '/course/info',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 资源库
  //   {
  //     id: 6,
  //     name: 'RESOURSE',
  //     path: '/resource',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'RESOURSE_LIST',
  //   //   path: '/resource/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'RESOURSE_EDIT',  // 前端路由名称
  //   //   path: '/resource/edit',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  // // 学校

  //   {
  //     id: 6,
  //     name: 'SCHOOL',  // 前端路由名称
  //     path: '/school',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'SCHOOL_LIST',  // 前端路由名称
  //   //   path: '/school/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 数据分析
  //   {
  //     id: 6,
  //     name: 'ANALYSIS',  // 前端路由名称
  //     path: '/analysis',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'ANALYSIS_LIST',  // 前端路由名称
  //   //   path: '/analysis/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'ANALYSIS_LIST',  // 前端路由名称
  //   //   path: '/analysis/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },


  //   // {
  //   //   id: 6,
  //   //   name: 'USERINFO',   //个人中心
  //   //   path: '/userinfo', 
  //   //   permission: ['add', 'edit','delete'] 
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'USERINFO_LIST',  //个人中心
  //   //   path: '/userinfo/list', 
  //   //   permission: ['add', 'edit','delete'] 
  //   // },

  //   // 师资管理teacher
  //   {
  //     id: 6,
  //     name: 'TEACHER',  //个人中心
  //     path: '/teacher',
  //     permission: ['add', 'edit', 'delete']
  //   },

  //   // {
  //   //   id: 6,
  //   //   name: 'TEACHER_LIST',  //个人中心
  //   //   path: '/teacher/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // {
  //   //   id: 6,
  //   //   name: 'TEACHER_INFO',  //个人中心
  //   //   path: '/teacher/info',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 学生管理
  //   {
  //     id: 6,
  //     name: 'STUDENT',  //个人中心
  //     path: '/student',
  //     permission: ['add', 'edit', 'delete']
  //   },

  //   // {
  //   //   id: 6,
  //   //   name: 'STUDENT_LIST',  //个人中心
  //   //   path: '/student/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // {
  //   //   id: 6,
  //   //   name: 'STUDENT_INFO',  //个人中心
  //   //   path: '/student/info',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // 系统管理
  //   {
  //     id: 6,
  //     name: 'SYSTEM',  //个人中心
  //     path: '/system',
  //     permission: ['add', 'edit', 'delete']
  //   },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_LIST',  //个人中心
  //   //   path: '/system/list',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_RECORD',  //个人中心
  //   //   path: '/system/record',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_SYBSTANCE',  //内容管理
  //   //   path: '/system/substance',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_INFORMATION',  //内容管理
  //   //   path: '/system/information',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },
  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_ROLE',  //内容管理
  //   //   path: '/system/role',
  //   //   permission: ['add', 'edit', 'delete']
  //   // },

  //   // {
  //   //   id: 6,
  //   //   name: 'SYSTEM_PERSONAL',  //内容管理
  //   //   path: '/system/personal',
  //   //   permission: ['add', 'edit', 'delete']
  //   // }
  // ];