module.exports={
    index:{
        语言:'语言',
        刷新:'刷新',
        关闭:'关闭',
        首页:"首页",
        信息:"信息",
        信息列表:"信息列表",
        编辑信息:"编辑信息",
        用户:"用户",
        用户列表:"用户列表",
        用户编辑:"用户编辑",
        课程:"课程",
        课程管理:"课程管理",
        系统管理:"系统管理",
        管理员管理:"管理员管理",
        课程信息:"课程信息",
        校内课程:"校内课程",
    }
    
    
    
}