import Layout from "@views/layout";

const resourceRouter = {
	path: "/course",
	name: "COURSE",
	component: Layout,
	redirect: {
		name: 'COURSE_LIST'
	},
	meta: {
		title: "课程管理",
		icon: "icon-kecheng",
		is_menu: true 
	},
	children: [{
			path: "list",
			name: "COURSE_LIST",
			meta: {
				title: "课程管理",
				keepAlive: false,
				icon: "icon-kecheng",
				is_menu: false,
				prentRoute: "COURSE"
			},
			component: () => import("@views/course/index.vue"),
		},
		{
			path: "info/:tecla_id",
			name: "COURSE_INFO",
			hidden: true,
			meta: {
				title: "课程信息",
				keepAlive: false,
				icon: "icon-kecheng",
				operate: true,
				is_menu: false,
				prentRoute: "COURSE"
			},
			component: () => import("@views/course/info.vue"),
		},
		

	],

};

export default resourceRouter;
